import React, {Component} from 'react';
import proptypes from 'prop-types';
import Table from "../table/Table";

class Cv extends Component {
    static propTypes={
        person: proptypes.object,
    }

    static defaultProps={
        person:{

        }
    }
    render() {
        let {person} = this.props;
        let tableData = {}
        tableData.name = person.name;
        tableData.family= person.family;
        tableData.birthday = person.birthday;
        tableData.mobile = person.mobile;
        tableData.email = person.email;
        tableData.address = person.address;
        return (
            <div className="cv row m-auto" style={{textAlign: Window.lang==='en'?'left':'right'}}>
                <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12 px-0 profile-image-div">
                    <img className="profile-image" src={person.image} alt={'not found'}/>
                </div>
                <div className="col-xl-8 col-lg-6 col-md-6 col-sm-12 col-12 px-0 profile-table-div">
                    <Table person={person} tableData={tableData}/>
                </div>
            </div>
        );
    }
}

export default Cv;