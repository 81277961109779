import React, {Component} from 'react';
import Socials from "../socials/Socials";

class Table extends Component {

    createBody = () => {
        let {person, tableData} = this.props;
        let keys = Object.keys(tableData);
        let arr = keys.map((field, index) => {
           return (
                <tr className="table-row" key={index}>
                    <td className="text px-1">{Window.app.translate(field)} :</td>
                    <td className="text font-weight-bold px-2">{person[Window.app.transform(field)]}</td>
                </tr>
            )
        })
        return arr;
    }

    render() {
        let {person} = this.props;
        return (
            <div className="m-auto">
                <table className="my-table">
                    <tbody className="table-body">
                    {this.createBody()}
                    </tbody>
                </table>
                <Socials person={person}/>
            </div>
        );
    }
}

export default Table;