const arr = ['name' , 'family' , 'address' , 'birthday' , 'mobile' ,]

export default (text)=>{
    if(arr.includes(text)) {
        switch (Window.lang) {
            case "fa":
                return 'fa_' + text;
            case "en":
                return text;
            default:
                return text;
        }
    }
    return text;
}
