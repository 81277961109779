import React, {Component} from 'react';
import telegram from './../../asset/img/socials/telegram.png'
import twitter from './../../asset/img/socials/twitter.png'
import instagram from './../../asset/img/socials/instagram.png'
import whatsapp from '../../asset/img/socials/whatsapp.png'
import skype from '../../asset/img/socials/skype.png'
import linkedin from '../../asset/img/socials/linkedin.png'

class Socials extends Component {
    render() {
        let {person} = this.props;
        return (
            <div className="socials">
                <div className="social">
                    <a href={person.linkedin}>
                        <img className="social-image" src={linkedin} alt={'linkedin'}/>
                    </a>
                </div>

                <div className="social">
                    <a href={person.instagram}>
                        <img className="social-image" src={instagram} alt={'instagram'}/>
                    </a>
                </div>

                <div className="social">
                    <a href={person.telegram}>
                        <img className="social-image" src={telegram} alt={'telegram'}/>
                    </a>
                </div>

                <div className="social">
                    <a href={person.skype}>
                        <img className="social-image" src={skype} alt={'skype'}/>
                    </a>
                </div>

                <div className="social">
                    <a href={person.twitter}>
                        <img className="social-image" src={twitter} alt={'twitter'}/>
                    </a>
                </div>

                <div className="social">
                    <a href={person.whatsapp}>
                        <img className="social-image" src={whatsapp} alt={'whatsapp'}/>
                    </a>
                </div>

            </div>
        );
    }
}

export default Socials;