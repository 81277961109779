import React, {Component} from 'react';
import Cv from "../components/cv/Cv";
import pouyaImage from './../asset/img/profile-image (1).png'
import './../asset/style/style.css'

class Home extends Component {
    render() {
        let person = {
            'name': 'Pouya',
            'fa_name':'پویا',
            'family': 'Ebrahimi',
            'fa_family':'ابراهیمی',
            'birthday': '1994/11/26',
            'fa_birthday': '1373/09/05',
            'mobile': '+989364007367',
            'fa_mobile': '09364007367',
            'email': 'p.ebrahimi163@gmail.com',
            'image': pouyaImage,
            'address': 'Iran, Tehran',
            'fa_address':'تهران',

            'linkedin': 'https://www.linkedin.com/in/pouyaebrahimi/',
            'instagram':'https://www.instagram.com/pouya.ebrahimi.kazaj/',
            'telegram':'https://t.me/EbrahimiPouya',
            'skype':'skype:live:.cid.18088697c3b6140d?userinfo',
            'twitter':'https://twitter.com/PouyaEbrahimi9',
            'whatsapp':'https://wa.me/989364007367',
        }

        let enLangStyle = {
            fontFamily: 'sans-serif',
            direction: 'ltr',
        }

        let faLangStyle ={
            fontFamily: 'vazir',
            direction: 'rtl',
        }

        return (
            <div className="container home" style={ Window.lang==='en'?enLangStyle:faLangStyle}>
                <button className="change-lang-btn" onClick={()=>{
                    Window.app.lang(Window.lang ==='en'? 'fa':'en')
                    this.setState({})
                } }>{Window.lang==='en'?"Fa":"En"}</button>

                <Cv person={person}/>
            </div>
        );
    }
}

export default Home;